'use client'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { status, subscribe, unsubscribe } from '../lib/push'
import Button from './ui-other/basic/Button'
import { Spinner } from './ui-other/basic/Spinner'

import PushIcon from 'public/img/ci-icons/pushnachrichten.svg'
import PushIconOff from 'public/img/ci-icons/pushnachrichten-off.svg'

interface PushButtonProps {
  channel: string
}

export function PushButton({
  channel
}: PushButtonProps): ReactElement<any, any> | null {
  const [active, setActive] = useState<
    undefined | 'unsupported' | 'on' | 'off'
  >(undefined)

  useEffect(() => {
    status(channel).then(setActive)
  }, [channel])

  const onClick = useCallback(async () => {
    if (active === 'on') {
      setActive(undefined)
      unsubscribe(channel).then(setActive)
    } else if (active === 'off') {
      setActive(undefined)
      subscribe(channel)
        .then(setActive)
        .catch(() => setActive('off'))
    }
  }, [channel, active, setActive])

  switch (active) {
    case 'on':
      return (
        <Button
          iconOnly
          small
          color="gray"
          tooltip="Benachrichtigungen deaktivieren"
          onClick={onClick}
        >
          <PushIconOff />
        </Button>
      )
    case 'off':
      return (
        <Button
          iconOnly
          small
          color="primary"
          tooltip="Benachrichtigungen aktivieren"
          onClick={onClick}
        >
          <PushIcon />
        </Button>
      )
    case 'unsupported':
      return <React.Fragment />
    default:
      return (
        <Button
          small
          iconOnly
          color="transparent"
          tooltip="Benachrichtigungsstatus wird geladen"
        >
          <Spinner loading={true} />
        </Button>
      )
  }
}
